.container{
    width: 100%;
    height: 100vh;
}

.wrapper{
    width:100%;
    height: inherit;
    display: flex;
}

.left,.right{
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.left{
    gap:1rem;
}
.right{
    align-items: flex-start;
}
.message h1{
    text-align: center;
    font-size: 2.5rem;
    color:#02df75;
    text-shadow: -3px 3px 3px rgba(0,0,0,0.6);
}

.loginForm{
    width: 85%;

}
form{
    width: 100%;
}

.formContainer{
    width: 100%;
    border-radius: 20px;
    padding: 3rem 2rem;
    background-color: #fff;
    display: flex;
    box-shadow: -1px 1px 18px 1px rgba(0,0,0,0.3);
    flex-direction: column;
    gap: 1.5rem;
}

.welcome{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.welcome p{
    font-size: 23px;
    font-weight: 500;
    text-align: center;
}

.welcome h2{
    font-size: 30px;
    font-weight: 700;
    color: #06C167;
    text-align: center;
}

.inputField{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.inputField div{
    width: 100%;
    position: relative;
}
.inputContainer{
    width: 100%;
    padding: 0 1rem;
}


.forgotPassword span a{
    font-size: 14px;
    color: #06C167;
}

.loginBtn{
    display: flex;
    justify-content: center;
}



.loginBtn button:hover{
    background-color: #06C167;
    color: white;
}

.notHaveAcc{
    display: flex;
    margin-top: -10px;
    justify-content: center;
}
.notHaveAcc span a{
    color: #06C167;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
}

.notHaveAcc span{
    color: #06C167;
    font-weight: 700;
}

.hidePassword i{
    position: absolute ;
    top:-34px ;
    right: 20px;
    cursor: pointer;
    color: rgb(172, 171, 171);
}

.error{
    background-color: #CDF3E1;
    padding: 1rem;
    border-radius: 12px;
    text-align: center;
}
.error span{
    font-size: 0.9rem;
    font-weight: 600;
    color: rgb(131,131,145);
}

@media screen and (max-width:850px){
    .wrapper{
        flex-direction: column;
        gap:3rem;
    }
    .gifImg{
        width: 300px;
        height: 250px;
    }

    .gifImg img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .loginForm{
        margin: auto;
        margin-bottom: 3rem;
    }
    .inputContainer{
        padding: 0;
    }
}

@media screen and (max-width:450px){
    .loginForm{
        width:95%
    }
    .message h1{
        font-size: 2rem;
    }

    .welcome h2{
        font-size: 25px;
    }
    .welcome p{
        font-size: 20px;
    }
    
    .formContainer{
        padding: 2rem 1rem;
    }
}